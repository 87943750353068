.LoadingDots_root__fhl0Y {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  place-content: center;
  place-items: center;
}

.LoadingDots_inner__piP_N {
  border-radius: 9999px;
  animation-name: LoadingDots_blink__KuINI;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.LoadingDots_inner__piP_N:nth-of-type(2) {
  animation-delay: 0.2s;
}

.LoadingDots_inner__piP_N:nth-of-type(3) {
  animation-delay: 0.4s;
}

@keyframes LoadingDots_blink__KuINI {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

